import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Carousel from '@webOrganisms/Carousel/Carousel';
import FeaturedDetailed from '@localTypes/FeaturedDetailed';
import YoutubeEmbed from '@webAtoms/YoutubeEmbed/YoutubeEmbed';
import * as Route from '@routes';
import * as styles from './featuredBuild.module.scss';

export interface FeaturedBuildProps {
  featured: FeaturedDetailed;
}

export const FeaturedBuild: React.FC<FeaturedBuildProps> = ({ featured }) => {
  let slides = featured.images.map(image => ({
    slide: (
      <Box className={styles.featuredBuildColumnsCarouselSlide}>
        <img
          loading="eager"
          className={styles.featuredBuildColumnsCarouselSlideImage}
          alt={featured.title}
          src={image}
        />
      </Box>
    ),
  }));

  if (featured.video) {
    slides = [
      {
        slide: (
          <Box className={styles.featuredBuildColumnsCarouselSlide}>
            <YoutubeEmbed
              className={styles.featuredBuildColumnsCarouselSlideVideo}
              videoId={featured.video}
            />
          </Box>
        ),
      },
      ...slides,
    ];
  }
  return (
    <Box>
      <Container>
        <Box className={styles.featuredBuildInner}>
          <Box className={styles.featuredBuildBreadcrumbs}>
            <a href={Route.featuredBuilds()}>Featured builds</a>
            <span>&gt;</span>
            <span>{featured.title}</span>
          </Box>
          <Box className={styles.featuredBuildHeading}>
            <h1>{featured.title}</h1>
            {featured.traditionalName && <h2>{featured.traditionalName}</h2>}
          </Box>
          <Box>
            <img loading="eager" alt={featured.title} src={featured.hero} />
          </Box>
          <Box
            className={styles.featuredBuildBody}
            htmlContent={featured.body}
          />
          <Box className={styles.featuredBuildColumns}>
            <Box className={styles.featuredBuildColumnsSpecifications}>
              <h3>Specifications</h3>
              <table>
                <tbody>
                  <tr>
                    <td>Modules</td>
                    <td>{featured.features.get('modules')}</td>
                  </tr>
                  <tr>
                    <td>Living area</td>
                    <td>{featured.features.get('area')}m2</td>
                  </tr>
                  <tr>
                    <td>Deck</td>
                    <td>{featured.features.get('deck')}m2</td>
                  </tr>
                  <tr>
                    <td>From</td>
                    <td>${featured.price.toLocaleString('en-AU')} + GST</td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Box className={styles.featuredBuildColumnsCarousel}>
              <Carousel type="mixed" slides={slides} />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FeaturedBuild;
